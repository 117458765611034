.login-page {
  #root > div {
    padding-left: 0;
  }
}

.MuiContainer-root.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  .login-form {
    display: block;
    width: 100%;
    max-width: 500px;
    padding: 2rem;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.09);
  }
}
